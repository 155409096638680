/*CSS overrides*/
.MuiDataGrid-main {
    border: 1px solid rgba(0, 0, 0, 0.12);
}
.rdt_TableRow{
    height: 60px;
}
.rdt_TableCol{
    font-family: "Montserrat";
    font-size: 14px;
    color: #8A8A8A;
}

.rdt_TableCol_Sortable{
    flex-direction: row-reverse;
    justify-content: flex-end !important;
}

.rdt_TableCol_Sortable .__rdt_custom_sort_icon__{
    display: flex;
    flex-direction: column;
}

.priority-data{
    display: flex;
}

.category-data{
    border-radius: 8px;
    padding: 6px 10px;
    align-items: center;
    color: #fff;
    font-weight: 700;
}

.status-data{
    border-radius: 8px;
    padding: 6px 10px;
    align-items: center;
    color: #011111;
    font-weight: 700;
}

.datatable-inbox .MuiDataGrid-cell:focus, .datatable-inbox .MuiDataGrid-cell:focus-within  {
    outline: none!important;
}

.datatable-inbox .MuiDataGrid-columnSeparator {
    display: none!important;
}

.datatable-inbox .action-dropdown-list .MuiButton-outlinedPrimary {
    border-color: #467599;
    color: #467599;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 14px;
    text-transform: capitalize;
    height: 40px;
    padding-right: 0;
}

.datatable-inbox .action-dropdown-list .MuiButton-endIcon{
    height: 40px;
    border-left: 1px solid #467599;
    display: flex;
    align-items: center;
    width: 39px;
    margin-right: 0;
    justify-content: center;
}

.datatable-inbox .MuiDataGrid-row:hover, .datatable-inbox .MuiDataGrid-row.focusing, .datatable-inbox .MuiDataGrid-row.Mui-selected {
    background-color: #E7EBF0!important;
}

.datatable-inbox .Mui-checked, .datatable-inbox .MuiCheckbox-indeterminate, .datatable-inbox .MuiDataGrid-row .MuiCheckbox-root.Mui-checked, .datatable-inbox .MuiDataGrid-row .MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: #467599!important;
}

.datatable-inbox .MuiDataGrid-selectedRowCount.css-de9k3v-MuiDataGrid-selectedRowCount {
    opacity: 0;
}

.linear-process {
    z-index: 13000;
}

.MuiDataGrid-root[aria-colcount="0"] .MuiDataGrid-main > div:first-child {
    height: auto !important;
    bottom: 0;
}