.search-menu {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 12px 16px 10px 16px;
}

.search-inbox {
    background: #EDEDED;
    margin: 16px;
    border-radius: 4px;
}

.search-menu-right {
    display: flex;
    align-items: center;
    justify-content: center;
}

.clear-background:hover{
    background: transparent!important;
}

.clear-background:hover .text-style{
    color: black !important;
}
.search-menu-right .MuiFormControl-root {
    background: #fff;
    border-radius: 4px;
    margin: 8px 0 8px 8px;
}
.search-menu-right .MuiFormControl-root .MuiInputBase-root {
    z-index: 2;
}
.MuiOutlinedInput-root {
    height: 40px;
}

.title-content {
    position: absolute;
    color: #666666;
    font-weight: 700;
    font-size: 13px;
    z-index: 2;
    top: 50%;
    left: 0;
    transform: translate(12px, -50%);
}

.MuiFormControl-root .MuiInputBase-root .MuiSelect-select{
    font-size: 14px;
    color: #212121;
}

.MuiOutlinedInput-root #status-multiple-checkbox{
    padding-left: 64px;
}

.MuiOutlinedInput-root #priority-multiple-checkbox{
    padding-left: 70px;
}

.MuiOutlinedInput-root #category-multiple-checkbox{
    padding-left: 86px;
}

.MuiOutlinedInput-root #scans-multiple-checkbox{
    padding-left: 118px;
}

/*.MuiPaper-root .MuiMenu-list{*/
/*    padding: 0;*/
/*}*/

/*.MuiPaper-root .MuiMenu-list li{*/
/*    background: #fff;*/
/*}*/

/*.MuiPaper-root .MuiMenu-list li:hover{*/
/*    background: #DFF4ED;*/
/*}*/

.MuiInputBase-root .MuiOutlinedInput-notchedOutline,
.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline{
    border: 1px solid #E0E0E0!important;
}

.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border: 1px solid #345C8B!important;
}

.has-selected.MuiFormControl-root{
    background: #9FD8DB;
    border-color: #9FD8DB!important;
    color: #193051;
    font-weight: 600;
    border-radius: 4px;
}

.has-selected.MuiFormControl-root .MuiInputBase-root {
    border-color: #9FD8DB!important;
}

.has-selected .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
    border-color: #9FD8DB!important;
}

.has-selected .title-content {
    color: #193051;
    font-weight: 600;
}

.MuiPaper-root li.MuiButtonBase-root:hover{
    background: #E7EBF0 !important;
}
