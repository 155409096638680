#left-menu .MuiAccordionSummary-content {
    max-width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

#left-menu .MuiAccordionSummary-content p{
    margin-left: 16px;
}

#left-menu.active .MuiAccordionSummary-content p{
    font-weight: 700;
    color: #345C8B;
}

#left-menu.active .MuiAccordionSummary-content svg path{
    fill: #345C8B;
}

#left-menu .menu-child{
    padding: 0 10px 10px 10px;
}

#left-menu .menu-child .left-nav-btn{
    margin: unset !important;
    padding: 0 !important;
    width: 100% !important;
}

#left-menu .menu-child .left-nav-btn.active{
    border-left: 3px solid #345C8B !important;
}

#left-menu .menu-child .left-nav-btn.active p{
    font-weight: 600 !important;
}