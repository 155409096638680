.control-display {
    display: flex;
    align-items: center;
}

.control-display:last-child {
    margin-bottom: 0;
}

.control-display .control-display-label {
    display: inline-block;
    width: auto;
}

.control-display .control-display-content{
    display: inline-block;
    flex-grow: 1;
}
