.button{
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 48px;
}
.button-style {
  margin-bottom: 2px;
}

.button-style:hover {
  background: #5883A3!important;
}

.button-style:hover .text-style {
  color: #fff!important;
}

.button.only-icon {
  text-align: center;
  justify-content: center;
}
.button.only-icon .image-style {
  margin: 0!important;
}
.button.clear-filter:hover .text-style{
  opacity: 0.7;
}

.left-nav-btn:hover, .button.left-nav-btn.active {
  background-color: #BDEAED !important;
}

.left-nav-btn:hover .text-style, .button.left-nav-btn.active .text-style{
  color: #345C8B !important;
  font-weight: 700!important;
}

.left-nav-btn:hover .btnNode path, .button.left-nav-btn.active .btnNode path{
  fill: #467599
}

.reset-btn {
  background-color: transparent;
}
.reset-btn:hover {
  cursor: pointer;
}

