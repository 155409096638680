.status-inbox {
    display: flex;
    align-items: center;
    margin: 4px;
    background: #FFFFFF;
    border: 1px solid #BEBEBE;
    padding: 12px 49px 13px 17px;
    cursor: pointer;
}
.status-inbox:hover {
    background: #ebebeb;
}

.status-inbox:active {
    background: #D9D9D9;
}

.active {
    background: #D9D9D9;
}
