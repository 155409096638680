.logout-wrapper {
    position: relative;
    background-color: #FFFFFF;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.logo-logout-left {
    position: fixed;
    top: 0;
    left: 0;
}

.center-logout {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    box-sizing: border-box;
    justify-content: center;
}

.logout-logo {
    background-color: #E4F4F9;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.logout-icon path {
    fill: #467599;
}

.link-logout {
    cursor: pointer;
}