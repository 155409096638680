.dropdown-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    position: relative;
}

.dropdown-container.open{
    background: #345C8B;
}

.dropdown-container:last-child {
    gap: 4px;
    border-radius: 4px;
}

.dropdown-label {
    color: white !important;
    font-size: 14px;
    font-weight: 400 !important;
    font-family: "Righteous" !important;
    padding: 0 10px 0 10px !important;
    min-width: auto !important;
    height: 100%;
    width: 100% !important;
    padding-right: 30px !important;
}

.dropdown-label.no-child {
    padding-right: 10px !important;
}

.dropdown-toggle-arrow {
    position: absolute;
    top: 0;
    right: 0;
}

.dropdown-menu-wrapper {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow: hidden;
}

.dropdown-menu-header {
    border-bottom: 1px solid #E0E0E0;
}

.dropdown-menu-header .MuiInputBase-root:before,
.dropdown-menu-header .MuiInputBase-root:hover:not(.Mui-disabled):before,
.dropdown-menu-header .MuiInputBase-root:after{
    border: unset;
}

.dropdown-menu-header .MuiInputBase-input,
.dropdown-menu-header .MuiInputBase-input::placeholder{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #666666;
    width: 88%;
}

.dropdown-menu-body {
    overflow-y: auto;
}

.dropdown-menu-body .MuiMenuItem-root{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: #011111;
    padding: 10px 16px;
}

.no-record-found {
    font-size: 14px;
    padding: 8px 16px;
}
