.alert-container{
    display: flex;
    align-items: center;
}

.alert-container.MuiAlert-filledError{
    background-color: #FFEAEA;
    color: #B00020;
    border-left: 4px solid #B20007;
    font-weight: 600;
}