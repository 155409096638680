.page-content {
    display: flex;
    padding-top: 58px;
    width: 100%;
}

.inbox-content {
    background: #fff;
    margin-bottom: 24px;
    border: 1px solid #E6E7E7;
    border-radius: 4px;

}

.inbox-content .MuiChip-root{
    height: unset;
}

.inbox-content .Mui-selected .MuiChip-root{
    background: #345C8B;
    color: #fff;
}

.inbox-content .MuiChip-root .MuiChip-label{
    padding: 4px 8px;
    line-height: 13px;
}

.pagination {
    margin-top: 25px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #011111;
    float: right
}
.pagination select {
    font-family: 'Montserrat';
    font-weight: 500;
    border: 1px solid #CCCFCF;
    border-radius: 4px;
    color: #4C4C4C;
    height: 32px;
    background: #fff;
    margin-right: 10px;
    appearance: none;
    background: url('../../assets/images/arrow_black_drop_down.svg') no-repeat 80% 50%;
    padding-left: 10px;
}

.pagination-flex {
    display: flex;
    align-items: center;
    color: #212121;
    font-family: 'Montserrat';
    font-weight: 500;
}

.pagination-numbers {
    display: flex;
    padding: 0 14px 0 0;
    align-items: center;
    list-style-type: none;
}

.pagination-numbers li {
    margin-right: 7px;
    margin-left: 7px;
    cursor: pointer;
}

.pagination-numbers li .page-link {
    border: none;
    color: #4C4C4C;
    background: transparent;
    cursor: pointer;
}
.pagination-numbers li.active .page-link {
    cursor: default;
}
.pagination-numbers li .page-link:hover {
    color: #467599
}

.pagination-numbers li.active .page-link{
    border: 1px solid #467599;
    border-radius: 4px;
}

.pagination .first-page, .pagination .previous-page, .pagination .next-page, .pagination .last-page {
    position: relative;
    top: 2px
}

.pagination-numbers li.disabled {
    cursor: unset;
    color: rgba(0, 0, 0, 0.18);
    fill: rgba(0, 0, 0, 0.18);
    pointer-events: none;
}

.no-data-available {
    min-height: calc(100vh - 411px);
    display: flex;
    align-items: center;
}
.inbox-datatable .rdt_TableCol_Sortable{
    color: #8A8A8A;
    font-weight: 700;
}
.inbox-datatable .rdt_TableCol_Sortable[disabled] {
    font-weight: 500;
}
.inbox-datatable .rdt_TableRow {
    color: #011111;
    font-weight: 500;
}

.inbox-datatable .rdt_TableRow:last-child .action-cell .actions-dropdown-for-action,
.inbox-datatable .rdt_TableRow:nth-last-child(2) .action-cell .actions-dropdown-for-action{
    bottom: 40px;
    top: auto;
}

/*.inbox-datatable > div {*/
    /*overflow-y: auto;*/
/*}*/
.inbox-datatable > div::-webkit-scrollbar {
    width: 4px;
}

.inbox-datatable > div::-webkit-scrollbar-thumb{
    border-radius: 2px;
    border-right: 2px solid #FFFFFF;
    background-color: #B3B8B8;
}

.inbox-datatable [data-field="actions"] {
    position: sticky!important;
    position: -webkit-sticky;
    right: 0;
    background: #fff;
    filter: drop-shadow(-4px 0px 20px rgba(0, 0, 0, 0.06));
    box-shadow: 0px 0px 3px 1px #eee;
}

.inbox-datatable .MuiDataGrid-columnHeader {
    font-size: 12px;
}

.inbox-datatable .MuiDataGrid-root {
    border: none;
}

.inbox-datatable .MuiDataGrid-columnHeader[data-field="actions"] {
    display: none;
}

.MuiDataGrid-columnHeaders .MuiDataGrid-sortIcon path{
    fill: #467599;
}

.MuiDataGrid-columnHeaders:before{
    content: 'Actions';
    position: absolute;
    background: #fff;
    filter: drop-shadow(-4px 0px 20px rgba(0, 0, 0, 0.06));
    box-shadow: 0px 0px 3px 1px #eee;
    right: 0;
    width: 57px;
    padding: 0 8px;
    font-family: "Montserrat";
    font-size: 13px;
    font-weight: 600;
    z-index: 2;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    max-height: 55px;
}
.actions-rules {
    padding: 0;
}

.actions-rules .action-title{
    font-weight: 600;
    font-family: 'Montserrat';
    color: #292929;
    font-size: 16px;
    padding: 8px 12px 15px;
}

.actions-rules ul li{
    margin: 0;
    padding: 10px 12px;
    cursor: pointer;
}

.actions-rules ul li:hover {
    background: #5883A3;
    color: #011111;
}

/*.actions-rules.for-email {*/
/*    border-top: 1px solid #E6E7E7;*/
/*}*/

.MuiDataGrid-row {
    cursor: pointer;
}

.actions-customize {
    position: absolute;
    top: 108px;
    right: 1px;
    width: 73px;
    background: #fff;
    box-shadow: 0px 0px 3px 1px #eee;
}

.datatable-inbox {

    position: relative;
}
.datatable-inbox .action-dropdown-list {
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.datatable-inbox .action-dropdown-list .image-action {
    transform: rotate(90deg);
}

.datatable-inbox .MuiDataGrid-columnHeaderTitle {
    font-weight: 600;
    color: #212121;
}

.toolbar{
    display: flex;
    padding: 0 14px 0 15px;
    height: 51px;
    justify-content: space-between;
}

.toolbar button:disabled{
    opacity: 0.3;
    color: #467599CC;
    cursor: unset;
    fill: rgba(0, 0, 0, 0.18);
    pointer-events: none;
}

.right-toolbar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.right-toolbar > .MuiButtonBase-root{
    min-width: 35px;
    height: 35px;
    border-radius: 50%;
}

.inbox-page .MuiCheckbox-root input{
    background: #FFFFFF;
    border: 1px solid #707070;
    border-radius: 3px;
}

.inbox-page .page-content-title{
    display: flex;
    justify-content: space-between;
    margin: 0;
}

.inbox-page .page-content-header{
    background: transparent;
    box-shadow: unset;
}

.rows-selected, .left-toolbar {
    display: flex;
    align-items: center;
}

.rows-selected {
    margin-right: 32px;
}

.rows-selected  span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #011111
}

.checkbox-col {
    position: relative;
}

.checkbox-col:before {
    content: '';
    margin-left: 2px;
    pading: 2px!important;
    position: absolute;
    background-color:  #395675;
    width: 4px;
    height: 48px;
    top: 2px;
    left: 0;
}

.MuiDataGrid-panel.MuiPopperUnstyled-root {
    inset: auto!important;
    top: -60px !important;
    right: 0 !important;
}

.actions-dropdown-inbox .selected-title{
    color: #345C8B!important;
}

.actions-dropdown-inbox.disabled .selected-title{
    color: rgba(0, 0, 0, 0.2) !important;
}

.inbox-page .MuiTab-root{
    min-width: fit-content;
    padding: 10px 40px;
    min-height: 48px;
    text-transform: uppercase;
}

.MuiDataGrid-columnHeader {
    outline: none!important;
}

.MuiDataGrid-cell[data-field="scan_result"]{
    font-weight: 600;
    color: #011111;
}

#actions-customized-menu[aria-hidden="true"] .MuiPaper-root{
    box-shadow: unset;
}

#actions-customized-menu .MuiPaper-root .MuiMenu-list{
    max-width: 228px;
    min-width: 120px;
}

.MuiDataGrid-selectedRowCount{
    visibility: hidden !important;
}

.MuiDataGrid-cellContent{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    white-space: break-spaces;
}