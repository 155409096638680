header {
    display: flex;
    align-items: center;
    padding: 0 16px;
    min-height: 58px;
    background: #193051;
    justify-content: space-between;
}
header.fixed {
    position: fixed;
    width: calc(100% - 32px);
    top: 0;
    left: 0;
    z-index: 10000;
}

header svg {
    width: 20px;
    height: 20px;
}

header svg path{
    fill: #9FD8DB;
}

.header-title {
    display: flex;
    align-items: center;
}

.user-profile {
    margin-left: auto;
}

.header-name {
    font-family: "Righteous";
}

