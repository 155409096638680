.table-scan-result {
    width: auto;
    display: inline-block;
    text-align: left;
    border-collapse: collapse;
}
.table-scan-result thead {
    background: #F5F5F5;
}

th, td {
    padding: 10px 25px;
}
