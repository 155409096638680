.attachment-download-wrapper {
    border: 1px solid #D5E2E3;
    border-radius: 4px;
    position: relative;
}

.btn-attachment-download {
    position: absolute;
    right: 10px;
    top: 10px;
}

.attachment-header {
    display: flex;
    align-content: center;
    padding-right: 30px;
}

.btn-attachment-download {
    background: white;
    border-radius: 50%;
    border: 1px dashed #ccc;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-attachment-download:hover {
    border-width: 2px;
    cursor: pointer;
}